export function convertToDate(_strDate: string | Date | undefined): Date {
  try {
    if (typeof (_strDate) == typeof (Date))
      return _strDate as Date;
    // return new Date();
    return new Date(_strDate as string);
  } catch (error) {
    console.error(error)
    return new Date();
  }
}